import React, { useEffect } from "react";
import "./App.scss";
import { useGeneralContext } from "./context";
import "./scss/index.scss";
import { BrowserRouter, RouterProvider } from "react-router-dom";
import { router } from "./routes";
import "./scss/index.scss";
import { ConfigProvider, theme } from "antd";
import NotificationProvider from "./context/notification-context/NotificationProvider";
import AppLoading from "./Components/appLoading";
import { messaging } from "./firebase/config";
import { getToken, onMessage } from "firebase/messaging";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MessageNotification from "./Components/message-notification";
const { defaultAlgorithm, darkAlgorithm } = theme;

function App() {
  const {
    state: { isDarkModeTheme, appLoading },
  } = useGeneralContext();
  const { REACT_APP_VAPID_KEY } = process.env;

	// async function requestPermission() {
	// 	try {
	// 		//requesting permission using Notification API
	// 		const permission = await Notification.requestPermission();

	// 		if (permission === 'granted') {
	// 			const token = await getToken(messaging, {
	// 				vapidKey: REACT_APP_VAPID_KEY,
	// 			});

	// 			//We can send token to server
	// 			// console.log('Token generated : ', token);
	// 		} else if (permission === 'denied') {
	// 			//notifications are blocked
	// 			alert('You denied for the notification');
	// 		}
	// 	} catch (error) {
	// 		// console.log('Error for notification: ', error);
	// 	}
	// }

	// useEffect(() => {
	// 	requestPermission();
	// }, []);

	// onMessage(messaging, (payload) => {
	// 	toast(<MessageNotification notification={payload.notification} />);
	// });

  return (
    <div className="app" data-theme="dark">
      <div className={isDarkModeTheme ? "darkmode_theme" : "lightmode_theme"}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#e63946",
            },
            algorithm: isDarkModeTheme ? darkAlgorithm : defaultAlgorithm,
          }}
        >
          {appLoading && <AppLoading />}
            <NotificationProvider>
              <BrowserRouter basename="/" />
              <RouterProvider router={router} />
            </NotificationProvider>
        </ConfigProvider>
        {/* <button onClick={() => toggleTheme()}>change mode {isDarkModeTheme}</button> */}
      </div>
      <ToastContainer />
    </div>
  );
}

export default App;