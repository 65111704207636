import AppLineChat from "../../../Components/lineChart";
import {
  daysDoneSvg,
  daysLeftSvg,
  daysMissedSvg,
  goalIcon,
  shareSvg,
  trackerDoneKey,
  trackerMissedKey,
  trashRed,
} from "../../../assets/svg";
import { Table, DatePicker, Popconfirm, Spin } from "antd";
import {
  missedTradeTableColumn,
  trackerjournalTableColumn,
} from "../../../utils/data";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import {
  deleteTracker,
  getJournals,
  getTrackerDetetails,
  getTrackerJournals,
  updateDocument,
  updateTrackerDayStatus,
  TRACKERS,
} from "../../../firebase/firebaseFunctions";
import { useNotificationContext } from "../../../context/notification-context/NotificationProvider";
import {
  getDayDoneMissedLeft,
  getPercentageValue,
  getTrackeCurrentrDay,
  getTrackerStatus,
} from "../../../utils/helpers";
import { useGeneralContext } from "../../../context";
import dayjs from "dayjs";
import "./styles.scss";
import JournaDetailsDrawer from "../../../Components/journalDrawer";
import NoTradeMoalReason from "../../../Components/trackerCard/notradeModalReason";
import ShareModal from "../../../Components/shareModal";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import Infometrics from "./infometrics";
import ReactMarkdown from "react-markdown";
import { getAiTradingRecommendations } from "../../../utils/openai";
import ButtonComponent from "../../../Components/Button/button";
dayjs.extend(isSameOrBefore);

const baseUrl = window.location.origin;
const shareTitle = "Checkout my trading tracker";

const TrackerDeatials = () => {
  const navigate = useNavigate();
  const loction = useLocation();
  const { trackerID } = useParams();
  const { showErrorMessage, showSuccessMessage } = useNotificationContext();
  const { toggleAppLoading } = useGeneralContext();
  const sharePage = loction.pathname.includes("/app") ? false : true;

  const [openShareModal, setOpenShareModal] = useState(false);
  const [tracker, setTracker] = useState(null);
  const [trackerJournalsLoading, setTrackerJournalsLoading] = useState(false);
  const [openTradeDetailsDrawer, setOpenTradeDetailsDrawer] = useState(false);
  const [selectedJournal, setSelectedJournal] = useState(null);
  const [trackerJournals, setTrackerJournals] = useState([]);
  const [journalFilterDates, setJournalFilterDates] = useState([]);
  const [showNoTradeModal, setShowNoTradeModal] = useState(false);
  const [missedDayDate, setMissedDayDate] = useState("");
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [openAiRecommendation, setOpenAiRecommendation] = useState("");
  const [openAiLoading, setOpenAiLoading] = useState(false);

  const trackerStatus = useMemo(() => {
    return getTrackerStatus(tracker?.startDate, tracker?.endDate);
  }, [tracker]);

  const currtDay = useMemo(() => {
    return getTrackeCurrentrDay(tracker?.dateRange, tracker?.weekends);
  }, [tracker]);

  const daysObject = useMemo(() => {
    return getDayDoneMissedLeft(tracker?.dateRange);
  }, [tracker]);
  const daysMarkedAsMissed = useMemo(() => {
    return tracker?.dateRange
      .filter((v) => v.status === "missed")
      .map((v) => v.date);
  }, [tracker]);

  const daysMissedWithoutReason = useMemo(() => {
    const arr = [];
    tracker?.dateRange.forEach((v, idx) => {
      if (dayjs().isAfter(dayjs(v?.date), "day") && v?.status === "") {
        arr.push({ ...tracker?.dateRange[idx], day: `Day ${idx + 1}` });
      }
    });
    return arr;
  }, [tracker]);

  const filteredJournals = useMemo(() => {
    let dateArr = [];
    if (journalFilterDates.length < 1) {
      dateArr = tracker?.dateRange
        .filter((t) => t?.status === "done")
        .map((t) => t.date);
    } else {
      dateArr = journalFilterDates;
    }
    let allJournals = [];
    trackerJournals?.forEach((journal) => {
      if (
        dateArr.includes(
          dayjs(journal?.timeStamp.toDate()).format("YYYY-MM-DD")
        )
      ) {
        allJournals.push(journal);
      }
    });
    return allJournals;
  }, [trackerJournals, journalFilterDates]);

  const trackerAnalytics = useMemo(() => {
    const dateArr = tracker?.dateRange
      .filter((v) => v.status === "done")
      .map((t) => t?.date);
    let tradeTaken = 0;
    let pairsTraded = [];
    let profit = {
      percentage: 0,
      count: 0,
    };
    let loss = {
      percentage: 0,
      count: 0,
    };
    let breakeven = {
      percentage: 0,
      count: 0,
    };
    trackerJournals.forEach((journal) => {
      if (
        dateArr.includes(
          dayjs(journal?.timeStamp.toDate()).format("YYYY-MM-DD")
        )
      ) {
        tradeTaken++;

        if (!pairsTraded.includes(journal.currencyPair)) {
          pairsTraded.push(journal.currencyPair);
        }
        if (journal?.tradeOutcome === "Profit") {
          profit.count++;
        }
        if (journal?.tradeOutcome === "Breakeven") {
          breakeven.count++;
        }
        if (journal?.tradeOutcome === "Loss") {
          loss.count++;
        }
      }
    });
    profit.percentage = getPercentageValue(profit.count, tradeTaken);
    loss.percentage = getPercentageValue(loss.count, tradeTaken);
    breakeven.percentage = getPercentageValue(breakeven.count, tradeTaken);

    return {
      tradeTaken,
      pairsTraded,
      profit,
      loss,
      breakeven,
    };
  }, [trackerJournals]);

  let currntDateObject = useMemo(() => {
    return tracker?.dateRange.find((v) =>
      dayjs(v?.date).isSame(dayjs(), "day")
    );
  }, [tracker]);

  const getTrackerDetailsFunc = async () => {
    toggleAppLoading();
    try {
      const res = await getTrackerDetetails(trackerID);
      setTracker(res);
      if(res?.aiRecommendation){
        setOpenAiRecommendation(res?.aiRecommendation);
      }
      getTrackerJournalsFunc({
        userId: res?.userId,
        startDate: res?.startDate,
        endDate: res?.endDate,
      });
    } catch (error) {
      // console.log(error.message);
      showErrorMessage(error.message);
      if (sharePage) {
        navigate("/", { replace: true });
      } else {
        navigate("/app/tracker", { replace: true });
      }
    } finally {
      toggleAppLoading(false);
    }
  };

  const disabledDate = (current) => {
    return (
      current < dayjs(tracker.startDate) ||
      current > dayjs(tracker.endDate) ||
      daysMarkedAsMissed?.includes(dayjs(current).format("YYYY-MM-DD"))
    );
  };

  const getTrackerJournalsFunc = async ({ userId }) => {
    setTrackerJournalsLoading(true);
    try {
      const res = await getJournals(userId, { trackerId: trackerID });
      setTrackerJournals(res);
    } catch (error) {
      // console.log(error);
    } finally {
      setTrackerJournalsLoading(false);
    }
  };

  const saveNoTradeReason = async (reason) => {
    let res = await updateTrackerDayStatus({
      reason,
      trackerId: tracker?.id,
      status: "missed",
      uniqueDateString: missedDayDate,
    });
    if (res?.id) {
      setTracker(res);
      showSuccessMessage("Tracker day updated");
    } else {
      showErrorMessage("An error occured");
    }
    setMissedDayDate("");
    setShowNoTradeModal(false);
  };

  const markDayAsDone = async () => {
    setUpdatingStatus(true);
    try {
      let res = await updateTrackerDayStatus({
        trackerId: tracker?.id,
        status: "done",
        uniqueDateString: currntDateObject?.date,
      });
      setTracker(res);
      showSuccessMessage("Tracker day updated");
    } catch (error) {
      showErrorMessage(error.message || "An error occured");
      if (error.message === "Error: No jornal record for today") {
        navigate("/app/journals/add");
      }
    } finally {
      setUpdatingStatus(false);
    }
  };

  const deleTrackerFUnc = async () => {
    try {
      await deleteTracker(tracker?.id);
      showSuccessMessage("Tracker deleted");
      navigate("/app/tracker");
    } catch (error) {
      showErrorMessage("An error occured");
    }
  };

  const Details = () => (
    <>
      <span className="tracker_status">
        <div /> {trackerStatus}
      </span>

      <div className="top_card">
        <div className="details_wrapper">
          <div className="details">
            <span className="title">Trade Tracker</span>
            <span className="day">{currtDay}</span>
            {currtDay === "Closed" && (
              <span className="date">
                Close Date:{" "}
                {dayjs(tracker?.endDate).format("dddd, DD MMMM YYYY")}
              </span>
            )}
            {currtDay === "Upcoming" && (
              <span className="date">
                Start Date:{" "}
                {dayjs(tracker?.startDate).format("dddd, DD MMMM YYYY")}
              </span>
            )}
            {currtDay.includes("Day") && (
              <span className="date">
                {dayjs().format("dddd, DD MMMM YYYY")}
              </span>
            )}
          </div>
          {goalIcon}
        </div>
        {currntDateObject?.status === "" && (
          <div className="question">
            <span>Did you trade today?</span>
            <small>
              (This is best updated at the end of your trading day.)
            </small>
            <div className="button_wrpper">
              <button onClick={markDayAsDone} className="yes">
                {updatingStatus ? <Spin /> : "Yes"}
              </button>
              <button
                className="no"
                onClick={() => {
                  setMissedDayDate(currntDateObject?.date);
                  setShowNoTradeModal(true);
                }}
              >
                No
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="tracker_days">
        <div className="single">
          {daysDoneSvg}
          <span>{daysObject.done} Day(s)</span>
          <span>Done</span>
        </div>
        <div className="single">
          {daysMissedSvg}
          <span>{daysObject.missed} Day(s)</span>
          <span>Missed</span>
        </div>
        <div className="single">
          {daysLeftSvg}
          <span>{daysObject.left} Day(s)</span>
          <span>Left</span>
        </div>
      </div>

      <div className="tracker_days_listing">
        <span className="title">{tracker?.trackerName}</span>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            flexWrap: "wrap",
          }}
        >
          <span className="sub_text">
            {dayjs(tracker?.startDate).format("DD MMM")}-
            {dayjs(tracker?.endDate).format("DD MMM")}
          </span>
          <span className="days_count">{tracker?.dateRange?.length} days</span>
        </div>

        <div className="days_listing">
          {tracker?.dateRange.map((day) => {
            if (dayjs(day?.date).isBefore(dayjs(), "day")) {
              return (
                <div
                  className={`day ${day.status === "" ? "missed" : day.status}`}
                >
                  <span>{dayjs(day.date).format("ddd")}</span>
                  <span>{dayjs(day.date).format("DD")}</span>
                </div>
              );
            } else {
              return (
                <div className={`day ${day.status}`}>
                  {/* <div className={`day ${day.status === '' ? 'missed' : day.status}`}> */}
                  <span>{dayjs(day.date).format("ddd")}</span>
                  <span>{dayjs(day.date).format("DD")}</span>
                </div>
              );
            }
          })}
        </div>
        <div className="keys">
          <span>Keys</span>
          <div className="done">
            {trackerDoneKey}
            <span>Done</span>
          </div>
          <div className="missed">
            {trackerMissedKey}
            <span>Missed</span>
          </div>
        </div>
      </div>

      {!sharePage && daysMissedWithoutReason.length > 0 && (
        <div className="missed_days">
          <span className="title">Missed Days</span>
          <span className="sub_text">Click a missed day to add status</span>

          <div className="missed_day_card_wrapper">
            {daysMissedWithoutReason.map((val) => {
              return (
                <div
                  className="missed_day_card"
                  onClick={() => {
                    if (sharePage) return;
                    setMissedDayDate(val?.date);
                    setShowNoTradeModal(true);
                  }}
                >
                  <span>{val?.day}</span>
                  <span>{dayjs(val.date).format("ddd, DD MMMM YYYY")}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
  useEffect(() => {
    getTrackerDetailsFunc();
  }, []);

  const cleanTradeData = (trades) => {
    return trades.map(
      ({
        currencyPair,
        entryPrice,
        lotSize,
        orderType,
        reason,
        status,
        stopLoss,
        takeProfit,
        tradeOutcome,
      }) => ({
        currencyPair,
        entryPrice,
        lotSize,
        orderType,
        reason,
        status,
        stopLoss,
        takeProfit,
        tradeOutcome,
      })
    );
  };

  const openAiFilteredTrades = cleanTradeData(trackerJournals);

  const handleGenerateInsights = async () => {
    setOpenAiLoading(true);
    const insights = await getAiTradingRecommendations(openAiFilteredTrades);
    setOpenAiRecommendation(insights);
    await updateDocument(TRACKERS, tracker?.id, { aiRecommendation: insights });
    setOpenAiLoading(false);
  };
  return (
    <>
      {tracker?.id && (
        <div className={`tracker_details_wrapper ${sharePage ? "share" : ""}`}>
          <div className="summary">
            <div className="title_wrapper">
              <span className="title">Tracker Summary</span>
              {!sharePage && (
                <>
                  <Popconfirm
                    title="Delete tracker"
                    description="Are you sure to delete this tracker?"
                    onConfirm={deleTrackerFUnc}
                    okText="Yes"
                    cancelText="No"
                  >
                    <div className="delete">{trashRed}</div>
                  </Popconfirm>
                  <button
                    className="button primary"
                    onClick={() => setOpenShareModal(true)}
                  >
                    {shareSvg}
                    <span>Share</span>
                  </button>
                </>
              )}
            </div>

            <div className="top_cards">
              <div className="card">
                <span>Trade Taken</span>
                <span>{trackerAnalytics.tradeTaken}</span>
              </div>
              <div className="card">
                <span>Pairs Traded</span>
                <span>{trackerAnalytics.pairsTraded.length}</span>
              </div>
              <div className="card">
                <span>% of Profit</span>
                <span>
                  {trackerAnalytics.profit.percentage}% (
                  <small>{trackerAnalytics.profit.count}</small>)
                </span>
              </div>
              <div className="card">
                <span>% of Loss</span>
                <span>
                  {trackerAnalytics.loss.percentage}% (
                  <small>{trackerAnalytics.loss.count}</small>)
                </span>
              </div>
              <div className="card">
                <span>% of Breakout</span>
                <span>
                  {trackerAnalytics.breakeven.percentage}% (
                  <small>{trackerAnalytics.breakeven.count}</small>)
                </span>
              </div>
            </div>

            <div className="m_details">
              <Details />
            </div>
            <div className="chart">
              ` `
              <AppLineChat showLines={false} />
            </div>

            <div className="table_wrapper">
              <div className="mar-b-16 flex_header">
                <span className="d-block">
                  {sharePage ? "Days Traded" : "Days You Traded"}
                </span>
                <div style={{ maxWidth: "250px", width: "100%" }}>
                  <DatePicker
                    disabledDate={disabledDate}
                    maxTagCount="responsive"
                    multiple
                    onChange={(_, dateString) => {
                      setJournalFilterDates(dateString);
                    }}
                  />
                </div>
              </div>
              <Table
                scroll={{ x: 400 }}
                className="journal_table"
                loading={trackerJournalsLoading}
                dataSource={filteredJournals}
                columns={trackerjournalTableColumn(navigate)}
                bordered={false}
                pagination={false}
                rowKey="id"
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      setOpenTradeDetailsDrawer(true);
                      setSelectedJournal(record);
                    },
                  };
                }}
                rowClassName="cursor-pointer"
              />
            </div>
            <div className="table_wrapper">
              <span className="d-block mar-b-16">
                {sharePage ? "Missed days reason" : "Days You Didn’t Trade"}
              </span>
              <Table
                scroll={{ x: 400 }}
                className="journal_table"
                dataSource={tracker.dateRange
                  .map((v, idx) => ({ ...v, day: `Day ${idx + 1}` }))
                  .filter((v) => v.status === "missed")}
                columns={missedTradeTableColumn}
                bordered={false}
                pagination={false}
              />
            </div>

            <div className="recomendation">
              <span className="d-block mar-b-16">Confluence Stats</span>
              <div>
                {!trackerJournalsLoading && (
                  <Infometrics
                    trades={trackerJournals}
                    trackerJournalsLoading={trackerJournalsLoading}
                  />
                )}
              </div>
              {currtDay !== "Closed" && (
                <div className="empty_state">
                  <span>
                    We'll offer recommendations based on your activity
                    throughout the tracker period
                  </span>
                </div>
              )}
              <ButtonComponent
                className="button primary ai-generate-btn"
                onClick={() => handleGenerateInsights()}
                buttonText="Generate AI Recommendation"
                loading={openAiLoading}
                margin = "2rem auto"
                marginBottom= "1rem"
                disabled={
                  openAiLoading
                    ? true
                    : false || openAiRecommendation
                    ? true
                    : false || currtDay !== "Closed"
                    ? true
                    : false
                }
              />
              <div className="recommendation-wrapper">
                <ReactMarkdown>{openAiRecommendation}</ReactMarkdown>
              </div>
            </div>
          </div>
          <aside>
            <Details />
          </aside>
        </div>
      )}
      {!sharePage && (
        <NoTradeMoalReason
          missedDate={missedDayDate}
          saveReason={saveNoTradeReason}
          visible={showNoTradeModal}
          onClose={() => setShowNoTradeModal(false)}
        />
      )}
      {!sharePage && (
        <ShareModal
          title={shareTitle}
          shareUrl={`${baseUrl}/share/${tracker?.id}`}
          visible={openShareModal}
          onClose={() => setOpenShareModal(false)}
        />
      )}
      <JournaDetailsDrawer
        visible={openTradeDetailsDrawer}
        onClose={() => setOpenTradeDetailsDrawer(false)}
        journalObject={selectedJournal}
      />
    </>
  );
};

export default TrackerDeatials;
