import React from 'react';
import { Spin } from 'antd';
import { string } from 'prop-types';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ButtonComponent = ({
	buttonText,
	icon,
	textColor,
	className,
	fontSize,
	bgcolor,
	boxShadow,
	iconColor,
	iconSize,
	buttonRadius,
	onClick,
	padding,
	textPadding,
	buttonWidth,
	buttonHeight,
	id,
	loading = false,
	type = 'button',
	disabled,
	...rest
}) => {
	return (
		<button
			onClick={onClick}
			type={type}
			disabled={disabled || loading}
			className={`btn ${className || ''} `}
			id={id}
			style={{
				backgroundColor: `${bgcolor}`,
				boxShadow: `${boxShadow}`,
				borderRadius: `${buttonRadius}`,
				padding: `${padding}`,
				width: `${buttonWidth}`,
				height: `${buttonHeight}`,
				...rest,
			}}
		>
			{!loading && (
				<>
					{icon && (
						<span style={{ color: `${iconColor}`, fontSize: `${iconSize}` }}>
							<FontAwesomeIcon icon={icon} />
						</span>
					)}
					<span
						className="btn-text"
						style={{
							color: `${textColor}`,
							fontSize: `${fontSize}`,
							padding: `${textPadding}`,
						}}
					>
						{buttonText}
					</span>
				</>
			)}
			{loading && <Spin />}
		</button>
	);
};

ButtonComponent.propTypes = {
	buttonText: string.isRequired,
};

export default ButtonComponent;
