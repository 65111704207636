import { useFormik } from 'formik';
import { closeCircle } from '../../assets/svg';
import * as Yup from 'yup';
import { Modal, Checkbox } from 'antd';
import CustomInput from '../forms/Input';
import TextArea from '../forms/TextArea';
import DateInput from '../forms/DateInput';
import { useEffect, useState } from 'react';
import { createTrackerFunc, getTrackerDisabledDate } from '../../firebase/firebaseFunctions';
import { useAuthContext } from '../../context/auth-context/AuthProvider';
import ButtonComponent from '../Button/button';
import { generateDateRangeForTracker } from '../../utils/helpers';
import moment from 'moment';
import dayjs from 'dayjs';
import { useNotificationContext } from '../../context/notification-context/NotificationProvider';

const daysArray = ['3', '7', '12', '14', '21', '30'];

const CreateTrackerModal = ({ onClose, visible, onDone }) => {
	const { user } = useAuthContext();

	const [trackerCreated, setTrackerCreated] = useState(false);
	const [creatingTracker, setCreatingTracker] = useState(false);
	const [dateToDisabled, setDateToDisabled] = useState([]);
	const [endDate, setEndDate] = useState('');
	const { showErrorMessage } = useNotificationContext();
	const onSubmit = async (values) => {
		const dateRange = generateDateRangeForTracker(values.startDate, values.noOfDays, values.weekends);
		const dateConflictArray = [];
		let dateConflict = dateRange.some((v) => {
			if (dateToDisabled.includes(v.date)) {
				dateConflictArray.push(v.date);
				return true;
			}
			return false;
		});

		if (dateConflict) {
			showErrorMessage(`Tracker date conflicting with other tracker (${dateConflictArray[0]}), you can only have on active tracker.`);
			return;
		}
		setCreatingTracker(true);
		try {
			await createTrackerFunc({ ...values, dateRange, userId: user.uid, aiRecommendation: "" });
			setEndDate(dateRange[dateRange.length - 1].date);
			setTrackerCreated(true);
			getTrackerDisabledDateFunc();
			onDone();
		} catch (error) {
		} finally {
			setCreatingTracker(false);
		}
	};
	const { handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched, resetForm } = useFormik({
		initialValues: {
			trackerName: '',
			noOfDays: '',
			startDate: '',
			description: '',
			weekends: false,
		},
		validateOnChange: true,
		validationSchema: Yup.object().shape({
			trackerName: Yup.string().required().min(3).label('Tracker Name'),
			noOfDays: Yup.string().required('Select number of days'),
			startDate: Yup.string().required('Start Date is required'),
		}),
		onSubmit,
	});

	const getTrackerDisabledDateFunc = async () => {
		try {
			const res = await getTrackerDisabledDate(user.uid);
			setDateToDisabled(res);
		} catch (error) {}
	};

	useEffect(() => {
		getTrackerDisabledDateFunc();
	}, []);
	return (
		<Modal footer={false} closable={false} className="custom_modal" width="560px" open={visible} afterClose={() => setTrackerCreated(false)} onCancel={() => onClose()}>
			{!trackerCreated ? (
				<div className="create_tracker_modal">
					<div className="top mar-b-29">
						<h4>Create Trading Tracker</h4>
						<span className="cursor-pointer" onClick={() => onClose()}>
							{closeCircle}
						</span>
					</div>

					<div className="forms">
						<CustomInput
							label="Tracker Name"
							placeholder="Enter tracker name"
							onChange={handleChange('trackerName')}
							errorVisible={touched['trackerName']}
							errorMessage={errors['trackerName']}
							onBlur={handleBlur('trackerName')}
							value={values.trackerName}
						/>
						<div style={{ position: 'relative' }}>
							<div className="days">
								{daysArray.map((day, idx) => (
									<span
										key={idx}
										className={`day ${values.noOfDays === day ? 'selected' : ''}`}
										onClick={() => {
											setFieldValue('noOfDays', day);
										}}
									>
										{day} days
									</span>
								))}
							</div>
							{touched['noOfDays'] && errors['noOfDays'] && <small style={{ position: 'absolute', bottom: '-18px', color: '#d50000' }}>{errors['noOfDays']}</small>}
						</div>
						<div>
							<Checkbox
								onChange={(e) => {
									setFieldValue('weekends', e?.target?.checked);
								}}
								checked={values?.weekends}
							>
								<span className="reason">Include Weekends</span>
							</Checkbox>
						</div>
						<DateInput
							label="Start Date"
							placeholder="Select Date"
							onChange={(date) => {
								setFieldValue('startDate', date);
							}}
							disabledDate={(current) => {
								return current < dayjs().subtract(1, 'day') || dateToDisabled.includes(dayjs(current).format('YYYY-MM-DD'));
							}}
							errorVisible={touched['startDate']}
							errorMessage={errors['startDate']}
							onBlur={handleBlur('startDate')}
							value={values.startDate}
						/>
						<TextArea
							label="Description (Optional)"
							placeholder="Description"
							onChange={handleChange('description')}
							errorVisible={touched['description']}
							errorMessage={errors['description']}
							onBlur={handleBlur('description')}
							value={values.description}
						/>
					</div>

					<ButtonComponent buttonText="Done" className="button secondary mar-t-28" buttonWidth="100%" loading={creatingTracker} onClick={() => handleSubmit()} />
				</div>
			) : (
				<div className="success_modal">
					<span>Congratulation</span>
					<span>
						You have successfully created a trading tracker for {values?.noOfDays} days ({moment(values?.startDate).format('DD MMMM')} - {moment(endDate).format('DD MMMM')}){' '}
						{values.weekends ? '' : 'no weekends'}
					</span>
					<button
						className="button secondary w-full mar-t-28"
						onClick={() => {
							resetForm();
							onClose();
						}}
					>
						Back to Dashboard
					</button>
				</div>
			)}
		</Modal>
	);
};

export default CreateTrackerModal;
