import { Modal } from "antd";
import "./styles.scss";
import { closeCircle2 } from "../../assets/svg";
import { useAuthContext } from "../../context/auth-context/AuthProvider";

const WelcomeVideoModal = ({ onClose, visible }) => {

  return (
    <Modal
      footer={false}
      closable={false}
      className="custom_modal"
      width="1066px"
      open={visible}
      onCancel={() => onClose()}
    >
      <div className="welcome_video_wrapper">
        <span className="close_icon" onClick={() => onClose()}>{closeCircle2}</span>
        <h2>Welcome to spillmetrics 🎉🎉 </h2>
        <p className="text_details">
          Start with our user guide to help you understand how to navigate the
          platform.
        </p>
        <iframe
          src="https://www.youtube.com/embed/rUJ5KLbo7x8?si=g6UxsEsLGjBeRW5Y"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
          autoFocus={true}
        ></iframe>
      </div>
    </Modal>
  );
};

export default WelcomeVideoModal;
