import { Modal, Spin } from 'antd';
import './styles.scss';
import { checkIcon, closeCircle } from '../../assets/svg';
import { useState } from 'react';
import Services from '../../api/services';
import { useAuthContext } from '../../context/auth-context/AuthProvider';

const features = ['Unlimited Trade Journaling', 'Access to Trade tracker', 'Unlimited Projection Entries', 'Advance trade analysis included', 'Priority Support'];
const biilingType = [
	{
		title: 'Monthly',
		value: 'monthly',
		price: '8,000',
		billed: '8000.00',
		planCode: process.env.REACT_APP_PAYSTACK_MONTHLY,
	},
	{
		title: 'Annually (Save 10%)',
		value: 'annually',
		price: '7,200',
		billed: '86,400.00',
		planCode: process.env.REACT_APP_PAYSTACK_YEARLY,
	},
];

const PaymentPlanModal = ({ onClose, visible }) => {
	const [paymentType, setPaymentType] = useState(1);
	const { user } = useAuthContext();

	const [paymentLoading, setPaymentLoading] = useState(false);

	const initializePyamnet = async (val) => {
		if (!val?.planCode) return;
		setPaymentLoading(true);
		try {
			const res = await Services.subscribePayment({
				planCode: val?.planCode,
				amount: 1,
				email: user?.email,
				referral: user.uid,
			});
			let authorization_url = res?.data?.payload?.data?.authorization_url;
			let reference = res?.data?.payload?.data?.reference;
			window.open(authorization_url, '_blank');

			// Call handleVerifyPayment every 10 seconds
			const intervalId = setInterval(async () => {
				const verified = await handleVerifyPayment(reference);
				if (verified) {
					clearInterval(intervalId);
					setPaymentLoading(false);
					onClose();
					window.location.reload();
				}
			}, 10000);
		} catch (error) {
			setPaymentLoading(false);
		}
	};

	const handleVerifyPayment = async (ref) => {
		const res = await Services.verifyPayment(ref);
		return res.data.payload.data.status === 'success';
	};

	const BillingDetailsNode = () => {
		return (
			<div className="billing">
				<h6>Billing cycle</h6>
				<div className="tabs_wrapper">
					{biilingType.map((type, idx) => (
						<span key={idx} onClick={() => setPaymentType(idx)} className={biilingType[paymentType].value === type.value ? 'active' : ''}>
							{type.title}
						</span>
					))}
				</div>
				<div className="plan_payment">
					<span className="currency">NGN₦</span> <span className="price">{biilingType[paymentType].price}</span>{' '}
					<span className="billed">per month / {biilingType[paymentType].value === 'monthly' ? 'Billed monthly' : 'Billed yearly'}</span>
				</div>
				<span className="info">
					You will be charged {biilingType[paymentType].value === 'monthly' ? `₦${biilingType[paymentType].billed} every month` : `₦${biilingType[paymentType].billed} every year`}
				</span>
				<button onClick={() => initializePyamnet(biilingType[paymentType])}>{paymentLoading ? <Spin /> : 'Upgrade'}</button>
			</div>
		);
	};
	return (
		<Modal footer={false} closable={false} className="custom_modal payment_plan_modal" width="1066px" open={visible} onCancel={() => onClose()}>
			<div className="payment_plan_wrapper">
				<div className="plan_features">
					<h3>Spillmetrics Pro</h3>
					<span className="close_icon" onClick={() => onClose()}>
						{closeCircle}
					</span>
					<h6>You are currently on a free plan with limited access. Upgrade to get access to trade tracker and other additional features </h6>
					<div className="m_billing">
						<BillingDetailsNode />
					</div>
					<ul>
						{features.map((feature, idx) => (
							<li key={idx}>
								{checkIcon}
								<span>{feature}</span>
							</li>
						))}
					</ul>
				</div>
				<div className="billing_wrapper">
					<span className="close_icon" onClick={() => onClose()}>
						{closeCircle}
					</span>
					<BillingDetailsNode />
				</div>
			</div>
		</Modal>
	);
};

export default PaymentPlanModal;
