import React, { useReducer, useContext, useEffect } from "react";
import GeneralContext from "./GeneralContext";
import GeneralReducer from "./GeneralReducer";
import { EXAMPLE, TOGGLESIDEBAROPEN, TOGGLETHEME, TOGGLEAPPLOADING, SETPAYMENTMODAL, SETWELCOMEVIDEOMODAL } from "./types";
import { useMediaQuery } from "react-responsive";

const GeneralProvider = ({ children }) => {
  useMediaQuery(
    {
      query: "(prefers-color-scheme: dark)",
    },
    undefined,
    (prefersDark) => {
      dispatch({
        type: TOGGLETHEME,
        payload: prefersDark,
      });
    }
  );

  const initialState = {
    testExxampleString: "",
    sidebarOpen: JSON.parse(localStorage.getItem("sidebarOpen")),
    isDarkModeTheme: JSON.parse(localStorage.getItem("appDarkTheme")),
    paymentModalVisible: false,
    welcomeVideoModalVisible: false,
    appLoading: false,
  };

  const [state, dispatch] = useReducer(GeneralReducer, initialState);

  const runExampleFunction = () => {
    dispatch({
      type: EXAMPLE,
      payload: "Getting Started Example Dispatch",
    });
    return;
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("appDarkTheme")) == null) {
      toggleTheme()
      return
    }
    // eslint-disable-next-line
  }, [])

  const toggleTheme = () => {
    dispatch({
      type: TOGGLETHEME,
      payload: !state.isDarkModeTheme,
    });
  };

  const toggleSidebarOpen = () => {
    dispatch({
      type: TOGGLESIDEBAROPEN,
      payload: !state.sidebarOpen,
    });
  };
  const setSidebarOpen = (val) => {
    dispatch({
      type: TOGGLESIDEBAROPEN,
      payload: val,
    });
  };

  const toggleAppLoading = (val) => {
    dispatch({
      type: TOGGLEAPPLOADING,
      payload: typeof val === 'boolean' ? val : !state.appLoading,
    });
  };

  const setPaymentModalVisible = (val) => {
    dispatch({
      type: SETPAYMENTMODAL,
      payload: val,
    });
  };

  const setWelcomeVideoModalVisible = (val) => {
    dispatch({
      type: SETWELCOMEVIDEOMODAL,
      payload: val
    })
  }

  return (
    <GeneralContext.Provider
      value={{
        state: state,
        runExampleFunction,
        toggleTheme,
        toggleSidebarOpen,
        setSidebarOpen,
        toggleAppLoading,
        setPaymentModalVisible,
        setWelcomeVideoModalVisible,
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};

export const useGeneralContext = () => {
  const allContextValues = useContext(GeneralContext);
  return {
    ...allContextValues,
  };
};

export default GeneralProvider;
