import React, { useEffect } from "react";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import ButtonComponent from "../../Components/Button/button";
import CustomInput from "../../Components/forms/Input";
import PasswordInput from "../../Components/forms/passwordInput";
import * as Yup from "yup";
import { useAuthContext } from "../../context/auth-context/AuthProvider";
import { sendEmailVerification } from "firebase/auth";

import "./styles.scss";
import { useNotificationContext } from "../../context/notification-context/NotificationProvider";
import GoogleAuthButton from "../../Components/googleAuthButton";

const SignInPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    loginUser,
    logOut,
    user,
    loading: userDataLoading,
  } = useAuthContext();
  const { showErrorMessage, showInfoMessage } = useNotificationContext();

  const onSubmit = async (submitdata, actions) => {
    if (actions.isSubmitting) return;
    const { email, password } = submitdata;
    try {
      const userCredentials = await loginUser(email, password);
      if (!userCredentials?.user?.emailVerified) {
        await sendEmailVerification(userCredentials.user);
        await logOut();
        showInfoMessage("Please check your email for verification.");
        console.log("This user data has now been submitted!");
      } else {
        navigate({ pathname: "/app/overview", search: location.search });
      }
    } catch (error) {
      if (error.code === "auth/invalid-credential") {
        showErrorMessage("Email or password incorrect");
      } else {
        showErrorMessage("An error occured.");
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      email: Yup.string().required().email().label("Email"),
      password: Yup.string().required().min(6).label("Password"),
    }),
    onSubmit,
  });

  useEffect(() => {
    if (!userDataLoading && user?.emailVerified) {
      navigate({ pathname: "/app/overview", search: location.search });
    }
    // eslint-disable-next-line
  }, [user, userDataLoading]);

  if (userDataLoading && !user) {
    return <div className="auth-page"></div>;
  }

  return (
    <div className="auth-page">
      <div className="inner-container">
        <p className="heading">Welcome back!</p>
        <div className="o-auth_btns_container">
          <GoogleAuthButton text="Login with Google" />
        </div>
        <div className="or-flex">
          <hr className="flex-line" />
          <p className="flex-text">Or</p>
          <hr className="flex-line" />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="forms_input">
            <CustomInput
              label="Email address"
              placeholder="Enter email address"
              name="email"
              type="email"
              onChange={handleChange("email")}
              errorVisible={touched["email"]}
              errorMessage={errors["email"]}
              onBlur={handleBlur("email")}
              value={values.email}
            />
            <PasswordInput
              label="Password"
              placeholder="Enter password"
              onChange={handleChange("password")}
              errorVisible={touched["password"]}
              errorMessage={errors["password"]}
              onBlur={handleBlur("password")}
              value={values.password}
            />
          </div>
          <p
            className="forgot_password"
            onClick={() => navigate("/forget-password")}
          >
            Forgot Password
          </p>
          <ButtonComponent
            type="submit"
            loading={isSubmitting}
            className="submit_btn"
            buttonText="Login"
            buttonWidth="15rem"
            bgcolor="#8CFBDE"
          />
        </form>
        <p className="alternative-option">
          Don't have an account?{" "}
          <NavLink
            to={{ pathname: "/signup", search: location.search }}
            className="alternate-text"
          >
            {" "}
            Create New Account
          </NavLink>
        </p>
      </div>
    </div>
  );
};

export default SignInPage;
