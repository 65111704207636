import OpenAI from "openai";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

export const getAiTradingRecommendations = async (tradeData) => {
  const prompt = `
    Based on the following trading data, provide detailed but consise recommendations.
    Highlight successful strategies, suggest improvements for losses, 
    make sure to recommend soley based off the data.

    Data: 
    ${JSON.stringify(tradeData, null, 2)}

    Give actionable insights as if advising a trader directly but don't become ambigious.
    We also want to encourage the trader to stick with their strategy and backtest 
    to know what's not working. Add a percentage rating for my overall winrate too.
  `;

  try {
    const response = await openai.chat.completions.create({
      model: "gpt-3.5-turbo", // Updated to GPT-3.5 Turbo for lower cost
      messages: [
        { role: "system", content: "You are a professional trading mentor." },
        { role: "user", content: prompt },
      ],
      max_tokens: 600, // Limit output to control cost
    });
    console.log("Here is openai response: ", response);
    if (response?.choices?.length > 0) {
      return response.choices[0].message.content;
    } else {
      console.error("Unexpected response:", response);
      return "No insights available at the moment. Please try again.";
    }
  } catch (error) {
    console.error("Error fetching trading insights:", error);
    return "Could not generate recommendations at this time.";
  }
};
